@import url(http://fonts.googleapis.com/css?family=Patua+One);
@import url(http://fonts.googleapis.com/css?family=Monda:400,700);

@font-face {
    font-family: strangelove-narrow;
    /* pour IE */
    src : url('./fonts/populairelight-webfont.eot');
    /* others */
    src: local(':)'),
    url('./fonts/populairelight-webfont.ttf') format('truetype'),
    url('./fonts/populairelight-webfont.svg') format('svg'),
    url('./fonts/populairelight-webfont.woff') format('woff');
}

@font-face {
    font-family: strangelove-wide;
    /* pour IE */
    src : url('./fonts/populaire-webfont.eot');
    /* others */
    src: local(':)'),
    url('./fonts/populaire-webfont.ttf') format('truetype'),
    url('./fonts/populaire-webfont.svg') format('svg'),
    url('./fonts/populaire-webfont.woff') format('woff');
}

$text-color: #46433A;
$anchor-text-color: #f9b410;
$background-color: #b6b09d;//#231f20;

body {
	font-family: 'Monda', serif;

	color: $text-color;
	background-color: $background-color;
}

header,
main {
	padding: 0 10px;
}

h2 {
   font-weight: lighter;
   font-family: strangelove-narrow;
   font-size: 3.8em;
   margin-bottom: 0;
}

h2 i {
   margin:none;
   padding:none;
   font-size: 0.5em;
}

iframe {
       width: 90%;
}


/*** wrapper div for both header and main ***/
.wrapper {
	margin-top: 1%;
}

/*** anchor tags ***/
a:link,
a:visited,
a:hover,
a:active {
	color: $anchor-text-color;
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

a:link i,
a:visited i,
a:hover i,
a:active i {
	color: $text-color;
	text-decoration:none;
}

a:hover i {
	color: $anchor-text-color;
	text-decoration: none;
}

/*** main content list ***/
.main-list-item {
	font-weight: bold;
	font-size: 1.2em;
	margin: 0.8em 0;
}


/* override the left margin added by font awesome for the main content list,
since it must be aligned with the content */
.fa-ul.main-list {
	margin-left: 0;
}

/* list icons */
.main-list-item-icon {
	width: 36px;
	color: $text-color;
}

/*** logo ***/
.logo-container {
	text-align: center;
}

.logo {
	width: 300px;
	height: 104px;
	display: inline-block;
	background-size: cover;
	margin-top: 1em;
	//border-radius: 50%;
	//-moz-border-radius: 50%;
	//border: 2px solid $background-color;
	//box-shadow: 0 0 0 3px $text-color;
}

/*** author ***/
.author-container h1 {
	font-size: 2.8em;
	margin-top: 0;
	margin-bottom: 0;
	text-align: center;
}

/*** tagline ***/
.tagline-container p {
	font-size: 1em;
    text-align: center;
	margin-bottom: 2em;
	line-height: 100%;
}

/*** links ***/
.link-container {
	text-align: center;
	margin-bottom: 3em;
}

.link-container i {
	margin-left: 0.7em;
	font-size: 1.5em;
}

/******/
hr {
	border: 0;
	height: 1px;
	background-image: -webkit-linear-gradient(left, rgba(0,0,0,0), $text-color, rgba(0,0,0,0));
	background-image: -moz-linear-gradient(left, rgba(0,0,0,0), $text-color, rgba(0,0,0,0));
	background-image: -ms-linear-gradient(left, rgba(0,0,0,0), $text-color, rgba(0,0,0,0));
	background-image: -o-linear-gradient(left, rgba(0,0,0,0), $text-color, rgba(0,0,0,0));
}

/*** footer ***/
footer {
    background-color: $background-color;
	padding: 10px 0;
}

.poweredby {
	font-family: "Arial Narrow", Arial;
	font-size: 0.6em;
	line-height: 0.6em;
	padding: 0 5px;
}

.site-footer {
  width:100%;
  line-height:12px;
  font-size:11px;
  text-align: center;
}

main {
    margin-bottom: 50px;
}

/*** media queries ***/
/* X-Small devices (phones, 480px and up) */
@media (min-width: 480px) {
	/* wrapper stays 480px wide past 480px wide and is kept centered */
	.wrapper {
		width: 480px;
		margin: 1% auto 0 auto;
	}

    .logo {
        margin-top: 8em;
    }
}

/* All other devices (768px and up) */
@media (min-width: 768px) {
	/* past 768px the layout is changed and the wrapper has a fixed width of 680px
	to accomodate both the header column and the content column */
	.wrapper {
		width: 680px;
	}

	/* the header column stays left and has a dynamic width with all contents
	aligned right */
	header {
		float: left;
		width: 46%;
		text-align: right;
	}

	.author-container h1,
	.logo-container,
	.tagline-container p {
		text-align: right;
	}

	main {
		width: 46%;
		margin-left: 54%;
        margin-bottom: 80px;
        padding: 0;
	}

    footer {
        position: fixed;
        bottom: 0;
        right: 0;
    }
}

.game-banner {
    width: 100%;
}
